// ------------------------------------------------------------------------------
{
  Project       : www.ExEntryC.be
  Executable    :
  Author        : ExEntryC (Marc Georges Sr)
  Compiler      : Delphi 11.3
  File          : forms\Main.pas
  Contents      : www.ExEntryC.be
  (Re)Start     : 2024-09-26
  Upload history:
}
// ------------------------------------------------------------------------------
unit AppGlobals;
// ------------------------------------------------------------------------------
interface
uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WebLib.JSON,
  WebLib.Graphics,
  WebLib.Controls,
  WebLib.Forms,
  WebLib.Dialogs,
  WebLib.REST,
  WebLib.StdCtrls,
  System.DateUtils,
  Vcl.StdCtrls,
  Vcl.Controls,
  xAccount;
// ------------------------------------------------------------------------------
type
  tMode = (Development, Production);
  tAppGlobals = class
    isResultaat: string;
    function AppMode: tMode;
    function GetValidUser(var sNaam: string): boolean;
    procedure SetValidUser(const isValid: boolean; const sNaam: string);
    [async]
    function NewUser(var NewAccount: txAccount): string; async;
    procedure NewUserResponse(Sender: TObject; AResponse: string);
    [async]
    function GetAllUsers: string; async;
    procedure GetAllUsersResponse(Sender: TObject; AResponse: string);
    [async]
    function UserExists(const pLogin, pEmail: string): string; async;
    procedure UserExistsResponse(Sender: TObject; AResponse: string);
    procedure UserExistsError(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord; var Handled: boolean);
  end;
var
  AppInstance: tAppGlobals;
  // ------------------------------------------------------------------------------
implementation
uses
  fmain,
  WebLib.Storage,
  xSiteConstants;
// ------------------------------------------------------------------------------
function tAppGlobals.NewUser(var NewAccount: txAccount): string;
var
  NewUserRequest: THttpRequest;
  NewUserReqResult: TJSXMLHttpRequest;
  FullUrl: string;
  function Todate(a: TDate): string;
  begin
    Result := FormatDateTime(xFormatOneDate, a);
  end;
begin
  NewUserRequest := THttpRequest.create(Application);
  try
    NewUserRequest.OnResponse := NewUserResponse { wordt uitgevoerd in NewUserRequest.Perform() };
    NewUserRequest.Command := httpPOST;
    NewUserRequest.Headers.Clear;
    FullUrl := xBaseSite + phpInsertUser;
    FullUrl := FullUrl + '?' + fldLogin + '=' + NewAccount.xLogin;
    FullUrl := FullUrl + '&' + fldEmail + '=' + NewAccount.xEmail;
    FullUrl := FullUrl + '&' + fldNaam + '=' + NewAccount.xNaam;
    FullUrl := FullUrl + '&' + fldZip + '=' + NewAccount.xZip;
    FullUrl := FullUrl + '&' + fldSleutel + '=' + NewAccount.xSleutel;
    FullUrl := FullUrl + '&' + fldShortDesc + '=' + NewAccount.xShortDesc;
    FullUrl := FullUrl + '&' + fldLongDesc + '=' + NewAccount.xLongDesc;
    FullUrl := FullUrl + '&' + fldFree + '=' + NewAccount.xFree;
    FullUrl := FullUrl + '&' + fldCreated + '=' + Todate(NewAccount.xCreated);
    FullUrl := FullUrl + '&' + fldCreatedBy + '=' + NewAccount.xCreatedBy;
    FullUrl := FullUrl + '&' + fldModified + '=' + Todate(NewAccount.xModified);
    FullUrl := FullUrl + '&' + fldModifiedBy + '=' + NewAccount.xModifiedBy;
    if NewAccount.xOdooVersion <> EmptyStr then FullUrl := FullUrl + '&' + fldOdooVersion + '=' + NewAccount.xOdooVersion;
    FullUrl := FullUrl + '&' + fldIsTestAccount + '=' + booltostr(NewAccount.xIsTestAccount, False);
    FullUrl := FullUrl + '&' + fldLicenseStartdate + '=' + Todate(NewAccount.xLicenseStartDate);
    FullUrl := FullUrl + '&' + fldLicenseEnddate + '=' + Todate(NewAccount.xLicenseEndDate);
    FullUrl := FullUrl + '&' + fldLicenseOk + '=' + booltostr(NewAccount.xLicenseOk, False);
    FullUrl := FullUrl + '&' + fldTrialStartdate + '=' + Todate(NewAccount.xTrialStartDate);
    FullUrl := FullUrl + '&' + fldTrialEnddate + '=' + Todate(NewAccount.xTrialEndDate);
    FullUrl := FullUrl + '&' + fldTrialOk + '=' + booltostr(NewAccount.xTrialOk, False);
    FullUrl := FullUrl + '&' + fldNieuwsbrief + '=' + booltostr(NewAccount.xNieuwsbrief, False);
    if NewAccount.xAdres <> EmptyStr then FullUrl := FullUrl + '&' + fldAdres + '=' + NewAccount.xAdres;
    if NewAccount.xGemeente <> EmptyStr then FullUrl := FullUrl + '&' + fldGemeente + '=' + NewAccount.xGemeente;
    if NewAccount.xLand <> EmptyStr then FullUrl := FullUrl + '&' + fldLand + '=' + NewAccount.xLand;
    // FullUrl := FullUrl + '&&' + fldRegistratie + '=' + Todate(NewAccount.xRegistratie);
    if NewAccount.xBedrijf <> EmptyStr then FullUrl := FullUrl + '&' + fldBedrijf + '=' + NewAccount.xBedrijf;
    NewUserRequest.URL := FullUrl;
    NewUserReqResult := AWait(TJSXMLHttpRequest, NewUserRequest.Perform());
    isResultaat := NewUserReqResult.responseText;
    if NewUserReqResult.Status = 200 then
      begin // verwerk nieuwe gebtuiker
        isResultaat := NewUserReqResult.responseText;
      end;
  except Result := Format(errUnknownJson, ['NewUser']);
  end;
end;
// ------------------------------------------------------------------------------
procedure tAppGlobals.NewUserResponse(Sender: TObject; AResponse: string);
begin
  isResultaat := AResponse;
end;
// ------------------------------------------------------------------------------
function tAppGlobals.AppMode: tMode;
begin
  if SameText(TLocalStorage.GetValue('Mode'), 'Development') then Result := Development
  else Result := Production;
end;
// ------------------------------------------------------------------------------
function tAppGlobals.GetValidUser(var sNaam: string): boolean;
var
  ts: string;
begin
  ts := TSessionStorage.GetValue('IsMember');
  if ts = EmptyStr then Result := False
  else Result := StrToBool(ts);
  if Result then sNaam := TSessionStorage.GetValue('MemberName');
end;
// ------------------------------------------------------------------------------
procedure tAppGlobals.SetValidUser(const isValid: boolean; const sNaam: string);
begin
  TSessionStorage.SetValue('IsMember', booltostr(isValid, true));
  TSessionStorage.SetValue('MemberName', sNaam);
end;
// ------------------------------------------------------------------------------
function tAppGlobals.GetAllUsers: string;
var
  AllUsersRequest: THttpRequest;
  AllUsersReqResult: TJSXMLHttpRequest;
  FullUrl: string;
begin
  AllUsersRequest := THttpRequest.create(Application);
  try
    AllUsersRequest.OnResponse := GetAllUsersResponse { wordt uitgevoerd in AllUsersRequest.Perform() };
    AllUsersRequest.Headers.Clear;
    FullUrl := xBaseSite + phpLogin;
    AllUsersRequest.URL := FullUrl;
    AllUsersReqResult := AWait(TJSXMLHttpRequest, AllUsersRequest.Perform());
    Result := isResultaat;
  except Result := Format(errUnknownJson, ['GetAllUsers']);
  end;
end;
// ------------------------------------------------------------------------------
procedure tAppGlobals.GetAllUsersResponse(Sender: TObject; AResponse: string);
begin
  isResultaat := AResponse;
end;
// ------------------------------------------------------------------------------
function tAppGlobals.UserExists(const pLogin, pEmail: string): string;
var
  UserExistsRequest: THttpRequest;
  UserExistsReqResult: TJSXMLHttpRequest;
  FullUrl: string;
begin
  UserExistsRequest := THttpRequest.create(Application);
  try
    UserExistsRequest.OnResponse := UserExistsResponse { wordt uitgevoerd in UserExistsRequest.Perform() };
    UserExistsRequest.OnError := UserExistsError;
    UserExistsRequest.Command := httpPOST;
    UserExistsRequest.Headers.Clear;
    FullUrl := xBaseSite + phpValidUser + '?' + fldLogin + '=' + pLogin + '&&' + fldEmail + '=' + pEmail;
    UserExistsRequest.URL := FullUrl;
    form1.lblInsertError.Caption := form1.lblInsertError.Caption + #10 + UserExistsRequest.URL;
    UserExistsReqResult := AWait(TJSXMLHttpRequest, UserExistsRequest.Perform());
    form1.lblInsertError.Caption := form1.lblInsertError.Caption + #10 + UserExistsReqResult.responseText;
    Result := UserExistsReqResult.responseText;
    form1.lblInsertError.Caption := form1.lblInsertError.Caption + #10 + Result;
  except
  end;
end;
// ------------------------------------------------------------------------------
procedure tAppGlobals.UserExistsResponse(Sender: TObject; AResponse: string);
begin
  isResultaat := AResponse;
end;
// ------------------------------------------------------------------------------
procedure tAppGlobals.UserExistsError(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord; var Handled: boolean);
begin
  showmessage(ARequest.req.responseURL);
end;
// ------------------------------------------------------------------------------
end.
