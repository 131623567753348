// ------------------------------------------------------------------------------
{
  Project       : www.ExEntryC.be
  Executable    :
  Author        : ExEntryC (Marc Georges Sr)
  Compiler      : Delphi 11.3
  File          : forms\Main.pas
  Contents      : www.ExEntryC.be
  (Re)Start     : 2024-09-26
  Upload history:
}
// ------------------------------------------------------------------------------
unit xSiteConstants;
interface
// ------------------------------------------------------------------------------
const
  // ------------------------------------------------------------------------------
  tblGebruikers = 'Gebruikers';
  fldId = 'Id';
  fldLogin = 'Login';
  fldEmail = 'Email';
  fldNaam = 'Naam';
  fldZip = 'Zip';
  fldSleutel = 'Sleutel';
  fldShortDesc = 'ShortDesc';
  fldLongDesc = 'LongDesc';
  fldFree = 'Free';
  fldCreated = 'Created';
  fldCreatedBy = 'CreatedBy';
  fldModified = 'Modified';
  fldModifiedBy = 'ModifiedBy';
  fldOdooVersion = 'OdooVersion';
  fldIsTestAccount = 'IsTestAccount';
  fldLicenseStartdate = 'LicenseStartdate';
  fldLicenseEnddate = 'LicenseEnddate';
  fldLicenseOk = 'LicenseOk';
  fldTrialStartdate = 'TrialStartdate';
  fldTrialEnddate = 'TrialEnddate';
  fldTrialOk = 'TrialOk';
  fldNieuwsbrief = 'Nieuwsbrief';
  fldAdres = 'Adres';
  fldGemeente = 'Gemeente';
  fldLand = 'Land';
  fldRegistratie = 'Registratie';
  fldBedrijf = 'Bedrijf';
  // ------------------------------------------------------------------------------
  tblActivityLog = 'ActivityLog';
  fldlogMessage = 'logMessage';
  fldlogType = 'logType';
  fldlogDateTime = 'logDateTime';
  fldlogId = 'logId';
  // ------------------------------------------------------------------------------
  xFormatOneDate = 'yy-mm-dd';
  xBaseSite = 'https://exentryc.be/';
  xMySite = 'www.exentryc.be';
  xMyName = 'ExEntryC';
  // ------------------------------------------------------------------------------
  EmailPattern = '^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$';
  // ------------------------------------------------------------------------------
  phpInsertLog = 'insertlog.php';
  phpInsertUser = 'insertuser.php';
  phpLogin = 'login.php';
  phpValidUser = 'oneuser.php';
  phpSendMail = 'mail.php';
  // ------------------------------------------------------------------------------
  errUnknownJson = '[{"error":"unknown_in_%1"}]';
  // ------------------------------------------------------------------------------
implementation
// ------------------------------------------------------------------------------
end.
