// ------------------------------------------------------------------------------
{
  Project       : www.ExEntryC.be
  Executable    :
  Author        : ExEntryC (Marc Georges Sr)
  Compiler      : Delphi 11.3
  File          : forms\Main.pas
  Contents      : www.ExEntryC.be
  (Re)Start     : 2024-09-26
  Upload history:
}
// ------------------------------------------------------------------------------
unit xAccount;
// ------------------------------------------------------------------------------
interface
uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.REST,
  WEBLib.StdCtrls,
  System.DateUtils,
  Vcl.StdCtrls,
  Vcl.Controls;
// ------------------------------------------------------------------------------
type
  tMode = (Development, Production);
  txAccount = class
  private
    fLogin: string;
    fEmail: string;
    fNaam: string;
    fZip: string;
    fSleutel: string;
    fShortDesc: string;
    fLongDesc: string;
    fFree: string;
    fCreated: TDate;
    fCreatedBy: string;
    fModified: TDate;
    fModifiedBy: string;
    fOdooVersion: string;
    fIsTestAccount: boolean;
    fLicenseStartDate: TDate;
    fLicenseEndDate: TDate;
    fLicenseOk: boolean;
    fTrialStartDate: TDate;
    fTrialEndDate: TDate;
    fTrialOk: boolean;
    fNieuwsbrief: boolean;
    fAdres: string;
    fGemeente: string;
    fLand: string;
    fRegistratie: TDate;
    fBedrijf: string;
  public
    constructor create;
    destructor destroy; override;
    property xLogin: string read fLogin write fLogin;
    property xEmail: string read fEmail write fEmail;
    property xNaam: string read fNaam write fNaam;
    property xZip: string read fZip write fZip;
    property xSleutel: string read fSleutel write fSleutel;
    property xShortDesc: string read fShortDesc write fShortDesc;
    property xLongDesc: string read fLongDesc write fLongDesc;
    property xFree: string read fFree write fFree;
    property xCreated: TDate read fCreated write fCreated;
    property xCreatedBy: string read fCreatedBy write fCreatedBy;
    property xModified: TDate read fModified write fModified;
    property xModifiedBy: string read fModifiedBy write fModifiedBy;
    property xOdooVersion: string read fOdooVersion write fOdooVersion;
    property xIsTestAccount: boolean read fIsTestAccount write fIsTestAccount;
    property xLicenseStartDate: TDate read fLicenseStartDate write fLicenseStartDate;
    property xLicenseEndDate: TDate read fLicenseEndDate write fLicenseEndDate;
    property xLicenseOk: boolean read fLicenseOk write fLicenseOk;
    property xTrialStartDate: TDate read fTrialStartDate write fTrialStartDate;
    property xTrialEndDate: TDate read fTrialEndDate write fTrialEndDate;
    property xTrialOk: boolean read fTrialOk write fTrialOk;
    property xNieuwsbrief: boolean read fNieuwsbrief write fNieuwsbrief;
    property xAdres: string read fAdres write fAdres;
    property xGemeente: string read fGemeente write fGemeente;
    property xLand: string read fLand write fLand;
    property xRegistratie: TDate read fRegistratie write fRegistratie;
    property xBedrijf: string read fBedrijf write fBedrijf;
  end;
  // ------------------------------------------------------------------------------
implementation
uses
  WEBLib.Storage,
  xSiteConstants;
// ------------------------------------------------------------------------------
constructor txAccount.create;
begin
  fLogin := emptystr;
  fEmail := emptystr;
  fNaam := emptystr;
  fZip := emptystr;
  fSleutel := emptystr;
  fShortDesc := emptystr;
  fLongDesc := emptystr;
  fFree := emptystr;
  fCreated := Today;
  fCreatedBy := xMyName;
  fModified := Today;
  fModifiedBy := xMyName;
  fOdooVersion := emptystr;
  fIsTestAccount := True;
  fLicenseStartDate := 0;
  fLicenseEndDate := 0;
  fLicenseOk := False;
  fTrialStartDate := 0;
  fTrialEndDate := 0;
  fTrialOk := False;
  fNieuwsbrief := True;
  fAdres := emptystr;
  fGemeente := emptystr;
  fLand := emptystr;
  fRegistratie := Today;
  fBedrijf := emptystr;
end;
// ------------------------------------------------------------------------------
destructor txAccount.destroy;
begin
  inherited;
end;
// ------------------------------------------------------------------------------
end.
